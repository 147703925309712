import "./bebidas.css";
import "../global.css";
import amstelFardoLata350ml from "./images-bebidas/amstelFardoLata350ml.png";
import cerveja_Spaten_lata from "./images-bebidas/cerveja_Spaten_lata.png";
import cristalLataFardo from "./images-bebidas/cervejaCristalLataFardo.png";
import budweiserFardolata350ml from "./images-bebidas/budweiserFardolata350ml.png";
import cervejaPuroMalte from "./images-bebidas/cervejaPuroMalte.png";
import cervejaLataImperio from "./images-bebidas/cervejaLataImperio.png";
import energeticoMonsterGreen from "./images-bebidas/energeticoMonsterGreen.png";
import cocaColaZero from "./images-bebidas/coca-cola-2lZeroAcucar.png";
import sucoDelValleSabores from "./images-bebidas/sucoDelValleSabores.png";
import guaranaFabri2l from "./images-bebidas/guaranaFabri2l.png";
import vinhoParatudo from "../../assets/vinhoParatudo.png";
import vinhoBordo from "../../assets/vinhoTintoBordo.png";
import pingaPirassununga from "../../assets/pingaPirassununga.png";
import pingaMoenda from "../../assets/pingaMoenda.png";
import corote_sabores from "../../assets/corote_sabores.png";
import guaranaMaca from "../../assets/guaranaMaca.png";
import aguaTonica from "../../assets/aguaTonica.png";
import vinho_mioranza from "../../assets/vinho_mioranza.png";
import vinho_novecento from "../../assets/vinho_novecento.png";
import vinho_d_ouro from "../../assets/vinho_d_ouro.png";
import energetico_vibe_1L from "../../assets/energetico_vibe_1L.png";

function Bebidas() {
  return (
    <div id="bebidas">
      <div className="bebidas titulo-setor">BEBIDAS</div>
      <div className="container-ofertas promocoes-container">
        <div className="promo-item">
          <img src={vinho_mioranza} alt="Vinho Tinto Suave Mioranza" />
          <p> Vinho Tinto Suave Mioranza</p>
          <p className="embalagem-info"></p>
          <div className="title-oferta-medio">
            <h4 className="valor-item-grande">
              <span className="cifrao">R$</span>17,99
              <span className="valor-unidade">unid.</span>
            </h4>
          </div>
        </div>

        <div className="promo-item">
          <img src={vinhoBordo} alt="Vinho de Mesa Bordô Suave" />
          <p> Vinho de Mesa Bordô Suave </p>
          <p className="embalagem-info"></p>
          <div className="title-oferta-medio">
            <h4 className="valor-item-grande">
              <span className="cifrao">R$</span>17,99
              <span className="valor-unidade">unid.</span>
            </h4>
          </div>
        </div>

        <div className="promo-item">
          <img src={vinho_novecento} alt="Vinho Fino Novecento 900ml" />
          <p> Vinho Fino Novecento 900ml </p>

          <p className="embalagem-info"></p>
          <div className="title-oferta-medio">
            <h4 className="valor-item-grande">
              <span className="cifrao">R$</span>18,99
              <span className="valor-unidade">unid.</span>
            </h4>
          </div>
        </div>

        <div className="promo-item">
          <img src={vinho_d_ouro} alt="Vinho Tinto Suave Real D'Ouro 900ml" />
          <p> Vinho Tinto Suave Real D'Ouro 900ml </p>
          <p className="embalagem-info"></p>
          <div className="title-oferta-medio">
            <h4 className="valor-item-grande">
              <span className="cifrao">R$</span>19,99
              <span className="valor-unidade">unid.</span>
            </h4>
          </div>
        </div>

        <div className="promo-item">
          <img src={amstelFardoLata350ml} alt="Amstel Fardo Lata 350ml" />
          <p> Amstel lata 350ml</p>
          <p className="embalagem-info">Fardo c/12 R$ 47,88</p>

          <div className="title-oferta-medio">
            <h4 className="valor-item-grande">
              <span className="cifrao">R$</span>3,99
              <span className="valor-unidade">unid.</span>
            </h4>
          </div>
        </div>

        <div className="promo-item">
          <img src={cristalLataFardo} alt="cristal Lata Fardo" />
          <p> Crystal lata 350ml</p>
          <p className="embalagem-info">Fardo c/12 R$ 27,50</p>
          <div className="title-oferta-medio">
            <h4 className="valor-item-grande">
              <span className="cifrao">R$</span>2,29
              <span className="valor-unidade">unid.</span>
            </h4>
          </div>
        </div>

        <div className="promo-item">
          <img src={cervejaPuroMalte} alt="cerveja Puro Malte" />
          <p> Puro Malte lata 350ml </p>
          <p className="embalagem-info">Fardo c/12 R$ 27,50</p>
          <div className="title-oferta-medio">
            <h4 className="valor-item-grande">
              <span className="cifrao">R$</span>2,29
              <span className="valor-unidade">unid.</span>
            </h4>
          </div>
        </div>

        <div className="promo-item">
          <img src={cervejaLataImperio} alt="cerveja Lata Imperio" />
          <p> Império lata 350ml </p>
          <p className="embalagem-info">Fardo c/15 R$ 41,85</p>
          <div className="title-oferta-medio">
            <h4 className="valor-item-grande">
              <span className="cifrao">R$</span>2,79
              <span className="valor-unidade">unid.</span>
            </h4>
          </div>
        </div>

        <div className="promo-item">
          <img src={budweiserFardolata350ml} alt="budweiser Fardo lata 350ml" />
          <p> Budweiser lata 350ml </p>
          <p className="embalagem-info">Fardo c/12 R$ 45,48</p>
          <div className="title-oferta-medio">
            <h4 className="valor-item-grande">
              <span className="cifrao">R$</span>3,79
              <span className="valor-unidade">unid.</span>
            </h4>
          </div>
        </div>

        <div className="promo-item">
          <img src={cerveja_Spaten_lata} alt="Spaten Fardo Lata 350ml" />
          <p> Spaten lata 350ml </p>
          <p className="embalagem-info">Fardo c/12 R$ 55,10</p>
          <div className="title-oferta-medio">
            <h4 className="valor-item-grande">
              <span className="cifrao">R$</span>4,59
              <span className="valor-unidade">unid.</span>
            </h4>
          </div>
        </div>

        <div className="promo-item">
          <img src={corote_sabores} alt="Corote Sabores 500ml" />
          <p> Corote Sabores 500ml</p>
          <div className="title-oferta-medio">
            <h4 className="valor-item-grande">
              <span className="cifrao">R$</span>6,99
              <span className="valor-unidade">unid.</span>
            </h4>
          </div>
        </div>

        <div className="promo-item">
          <img src={energetico_vibe_1L} alt="Energetico Vibe 1L" />
          <p> Energético Vibe 1L </p>
          <div className="title-oferta-medio">
            <h4 className="valor-item-grande">
              <span className="cifrao">R$</span>9,99
              <span className="valor-unidade">unid.</span>
            </h4>
          </div>
        </div>

        <div className="promo-item">
          <img src={guaranaMaca} alt="Coca-Cola Zero" />
          <p>Guaraná Maçã 2Litros </p>
          <div className="title-oferta-medio">
            <h4 className="valor-item-grande">
              <span className="cifrao">R$</span>5,99{" "}
              <span className="valor-unidade">unid.</span>
            </h4>
          </div>
        </div>

        <div className="promo-item">
          <img src={guaranaFabri2l} alt="Coca-Cola Zero" />
          <p>Guaraná Fabri 2Litros </p>
          <div className="title-oferta-medio">
            <h4 className="valor-item-grande">
              <span className="cifrao">R$</span>5,99{" "}
              <span className="valor-unidade">unid.</span>
            </h4>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Bebidas;
