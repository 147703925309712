import "./mercearia.css";
import biscoitoAguaSalTriunfo345g from "./mercearia-images/biscoitoAguaSalTriunfo345g.png";
import biscoitoNikitoRecheadoChocolatoMorango from "./mercearia-images/biscoitoNikitoRecheadoChocolatoMorango.png";
import biscoitoSalgadoSolSaltPlus380g from "./mercearia-images/biscoitoSalgadoSolSaltPlus380g.png";
import biscoitoTortuguitaRecheadoSabores120g from "./mercearia-images/biscoitoTortuguitaSabores120g.png";
import biscoitoPiraqueCookiesChocolateOriginal80g from "./mercearia-images/biscoitoPiraqueCookiesChocolateOriginal80g.png";
import biscoitoTortuguitaMaizenaLeite170g from "./mercearia-images/biscoitoTortuguitaMaizenaLeite170g.png";
import leiteCondensadoTriangulo395g from "./mercearia-images/leiteCondensadoTriangulo395g.png";
import cremeLeiteNestle200g from "./mercearia-images/cremeLeiteNestle200g.png";
import doceLeiteDonLeche420g from "./mercearia-images/doceLeiteDonLeche420g.png";
import chocolateVariadosTortuguitaArcor146g from "./mercearia-images/chocolateVariadosTortuguitaArcor146g.png";
import salgadinhosTakisSabores56g from "./mercearia-images/salgadinhosTakisSabores56g.png";
import RefrescoFriscoSabores18g from "./mercearia-images/RefrescoFriscoSabores18g.png";
import gelatinaSolSabores20g from "./mercearia-images/gelatinaSolSabores20g.png";
import cafeSafraTradicional500g from "./mercearia-images/cafeSafraTradicional500g.png";
import cafeAltinopolisPremium500g from "./mercearia-images/cafeAltinopolisPremium500g.png";
import achocolatadoToddyOriginal750g from "./mercearia-images/achocolatadoToddyOriginal750g.png";
import achocolatadoChocolatto700g from "./mercearia-images/achocolatadoChocolatto700g.png";
import amacianteDownyConcentrado500ml from "./mercearia-images/amacianteDownyConcentrado500ml.png";
import sabaoemPoSurf5em1_800g from "./mercearia-images/sabaoemPoSurf5em1_800g.png";
import sabaoPoTixan800G from "./mercearia-images/sabaoPoTixan800G.png";
import detergenteYpe500ml from "./mercearia-images/detergenteYpe500ml.png";
import limpadorMultiUsoLimpol500ml from "./mercearia-images/limpadorMultiUsoLimpol500ml.png";
import aguaSanirariaTriex1Lt from "./mercearia-images/aguaSanirariaTriex1Lt.png";
import sodaCausticaIndaia1Kg from "./mercearia-images/sodaCausticaIndaia1Kg.png";
import papelHigienicoSulleg from "./mercearia-images/papelHigienicoSulleg.png";
import sabaoEmPastaDipol500g from "./mercearia-images/sabaoEmPastaDipol500g.png";
import fraldaDescartavelEvolutionXG from "./mercearia-images/fraldaDescartavelEvolutionXG.png";
import absorventeIntimusGel8unid from "./mercearia-images/absorventeIntimusGel8unid.png";
import shampooElseve200ml from "./mercearia-images/shampooElseve200ml.png";
import condicionadorElseve200ml from "./mercearia-images/condicionadorElseve200ml.png";
import panteneCondicionador150ml from "./mercearia-images/panteneCondicionador150ml.png";
import listerineCoolMintEnxaguanteBucal500ml from "./mercearia-images/listerineCoolMintEnxaguanteBucal500ml.png";
import cremeDentalOralB70g from "./mercearia-images/cremeDentalOralB70g.png";
import aparelho_de_Barbear_Gillette_Prestobarba3 from "./mercearia-images/aparelho_de_Barbear_Gillette_Prestobarba3.png";
import arrozDuSafra5kgTipo1 from "./mercearia-images/arrozDuSafra5kgTipo1.png";
import maioneseSoyaCaseiraPote500Gr from "./mercearia-images/maioneseSoyaCaseiraPote500Gr.png";
import milhoVerdeemConservaueroLata170g from "./mercearia-images/milhoVerdeemConservaueroLata170g.png";
import macarraoSemoladoBasilarEspaguete400g from "./mercearia-images/macarraoSemoladoBasilarEspaguete400g.png";
import molhoTomateFugini300g from "./mercearia-images/molhoTomateFugini300g.png";
import milho_conserva_fugini from "../../assets/milho_conserva_fugini.png";
import queijoParmesãoReliquiaSitio50gRalado from "./mercearia-images/queijoParmesãoReliquiaSitio50gRalado.png";
import feijaoCariocaTipo1DuSafraFeijãoCarioca from "./mercearia-images/feijaoCariocaTipo1DuSafraFeijãoCarioca.png";
import vinagreCasteloAlcoolColorido750ml from "./mercearia-images/vinagreCasteloAlcoolColorido750ml.png";
import seletaLegumes from "../../assets/seletaLegumes.png";
import seletaDueto from "../../assets/seletaDueto.png";
import ketchupHeinz from "../../assets/ketchupHeinz.png";
import arrozPreferencia5KG from "../../assets/arrozPreferencia.png";
import feijaoPatureba1kg from "../../assets/feijaoPatureba1kg.png";
import vinagreCristal from "../../assets/vinagreCristal.png";
import oleoAzeiteLisboa from "../../assets/oleoAzeiteLisboa.png";
import gilletePrestobarba from "../../assets/gilletePrestobarba.png";
import OldSpiceVip72h from "../../assets/OldSpiceVip72h.png";
import vejaMultiuso from "../../assets/vejaMultiuso.png";
import lustraMoveisPoliflor from "../../assets/lustraMoveisPoliflor.png";
import papelHigienicoNeve from "../../assets/papelHigienicoNeve.png";
import papelHigienicoNeve4rolos from "../../assets/papel_higienico_neve_4rolos.png";
import sacoLixoPoliluz from "../../assets/sacoLixoPoliluz.png";
import urcaLavaRoupas from "../../assets/urcaLavaRoupas.png";
import papel_aluminio_parana from "../../assets/papel_aluminio_parana.png";
import nissin_lamem_miojo from "../../assets/nissin_lamem_miojo.png";
import alcool_fortys_92 from "../../assets/alcool_Fortys.png";
import tempero_tempero_pronto_tchetto from "../../assets/tempero_tempero_pronto_tchetto.png";
import farofa_yoki from "../../assets/farofa_temperada_yoki.png";
import farofa_zei from "../../assets/farofa_temperada_zei.png";
import lar_doce_lar_500ml from "../../assets/lar_doce_lar_500ml.png";
import saco_de_lixo_verom from "../../assets/saco_de_lixo_verom.png";

function Mercearia() {
  return (
    <div>
      <div className="merceariaTitulo titulo-setor" id="mercearia">
        MERCEARIA
      </div>
      <div className="merceariaContent">
        <div className="container-ofertas promocoes-container">
          <div className="promo-item">
            <img
              src={seletaDueto}
              alt="Dueto em Conserva Fugini Milho e Ervilha Sachê 200g"
            />
            <p>Conserva Fugini Milho e Ervilha Sachê 200g</p>
            <div className="title-oferta-medio">
              <p className="embalagem-info"></p>
              <h4 className="valor-item-grande">
                <span className="cifrao">R$</span>3,99
                <span className="valor-unidade">Unid.</span>
              </h4>
            </div>
          </div>

          <div className="promo-item">
            <img
              src={milho_conserva_fugini}
              alt="Milho Conserva Fugini Sachê 200g"
            />
            <p>Milho Conserva Fugini Sachê 200g</p>
            <div className="title-oferta-medio">
              <p className="embalagem-info"></p>
              <h4 className="valor-item-grande">
                <span className="cifrao">R$</span>3,49
                <span className="valor-unidade">Unid.</span>
              </h4>
            </div>
          </div>

          <div className="promo-item">
            <img src={molhoTomateFugini300g} alt="molhoTomateFugini300g" />
            <p>Molho Tomate Fugini 300g</p>
            <br />
            <div className="title-oferta-medio">
              <p className="embalagem-info"></p>
              <h4 className="valor-item-grande">
                <span className="cifrao">R$</span>1,99
                <span className="valor-unidade">Unid.</span>
              </h4>
            </div>
          </div>

          <div className="promo-item">
            <img
              src={papel_aluminio_parana}
              alt="Macarrão Semolado Basilar Espaguete 400g"
            />
            <p> Papel Alumínio Paraná 4m</p>
            <br />
            <div className="title-oferta-medio">
              <p className="embalagem-info"></p>
              <h4 className="valor-item-grande">
                <span className="cifrao">R$</span>3,99
                <span className="valor-unidade">Unid.</span>
              </h4>
            </div>
          </div>

          <div className="promo-item">
            <img
              src={macarraoSemoladoBasilarEspaguete400g}
              alt="Macarrão Semolado Basilar Espaguete 400g"
            />
            <p> Macarrão Basilar Espaguete/Parafus 400g</p>
            <div className="title-oferta-medio">
              <p className="embalagem-info"></p>
              <h4 className="valor-item-grande">
                <span className="cifrao">R$</span>4,39
                <span className="valor-unidade">Unid.</span>
              </h4>
            </div>
          </div>

          <div className="promo-item">
            <img src={nissin_lamem_miojo} alt="Macarrão Nissin Lámen Sabores" />
            <p> Macarrão Nissin Lámen Sabores</p>
            <br />
            <div className="title-oferta-medio">
              <p className="embalagem-info"></p>
              <h4 className="valor-item-grande">
                <span className="cifrao">R$</span>3,49
                <span className="valor-unidade">Unid.</span>
              </h4>
            </div>
          </div>

          <div className="promo-item">
            <img src={farofa_yoki} alt="Farofa Yoki Tradicional" />
            <p>Farofa Yoki Tradicional</p>
            <br />
            <div className="title-oferta-medio">
              <p className="embalagem-info"></p>
              <h4 className="valor-item-grande">
                <span className="cifrao">R$</span>5,99
                <span className="valor-unidade">Unid.</span>
              </h4>
            </div>
          </div>

          <div className="promo-item">
            <img src={farofa_zei} alt="Farofa Zei Temperada" />
            <p>Farinha de mandioca Zei Temperada </p>
            <br />
            <div className="title-oferta-medio">
              <p className="embalagem-info"></p>
              <h4 className="valor-item-grande">
                <span className="cifrao">R$</span>5,99
                <span className="valor-unidade">Unid.</span>
              </h4>
            </div>
          </div>

          <div className="promo-item">
            <img
              src={tempero_tempero_pronto_tchetto}
              alt="Tempero Pronto Tchetto Sabores"
            />
            <p>Tempero Pronto Tchetto Sabores</p>
            <br />
            <div className="title-oferta-medio">
              <p className="embalagem-info"></p>
              <h4 className="valor-item-grande">
                <span className="cifrao">R$</span>3,99
                <span className="valor-unidade">Unid.</span>
              </h4>
            </div>
          </div>

          <div className="promo-item">
            <img src={alcool_fortys_92} alt="Vinagre Cristal 750ml" />

            <p>
              Álcool 92% Fortys
              <span
                style={{
                  backgroundColor: "red",
                  fontSize: "0.7em",
                  padding: "5px",
                }}
              >
                (próprio para acender churrasqueira)
              </span>
            </p>

            <br />
            <div className="title-oferta-medio">
              <h4 className="valor-item-grande">
                <span className="cifrao">R$</span>9,99
                <span className="valor-unidade">Unid.</span>
              </h4>
            </div>
          </div>

          <div className="promo-item">
            <img src={sabaoEmPastaDipol500g} alt="Sabão em Pasta Dipol 500g" />
            <p> Sabão em Pasta Dipol 500g</p>
            <div className="title-oferta-medio">
              <p className="embalagem-info"></p>
              <h4 className="valor-item-grande">
                <span className="cifrao">R$</span>6,99
                <span className="valor-unidade">Unid.</span>
              </h4>
            </div>
          </div>

          <div className="promo-item">
            <img src={aguaSanirariaTriex1Lt} alt="Água Sanitária Triex 1Lt" />
            <p>Água Sanitária Triex 1Lt</p>
            <div className="title-oferta-medio">
              <p className="embalagem-info"></p>
              <h4 className="valor-item-grande">
                <span className="cifrao">R$</span>3,49
                <span className="valor-unidade">Unid.</span>
              </h4>
            </div>
          </div>

          <div className="promo-item">
            <img
              src={papelHigienicoNeve4rolos}
              alt="Neve Papel Higiênico Toque de Seda, 12 rolos"
            />
            <p>Neve Papel Higiênico 4rolos</p>
            <div className="title-oferta-medio">
              <p className="embalagem-info"></p>
              <h4 className="valor-item-grande">
                <span className="cifrao">R$</span>7,99
                <span className="valor-unidade">Unid.</span>
              </h4>
            </div>
          </div>

          <div className="promo-item">
            <img src={detergenteYpe500ml} alt="Detergente Ype 500ml" />
            <p>Detergente Ype 500ml</p>
            <div className="title-oferta-medio">
              <p className="embalagem-info"></p>
              <h4 className="valor-item-grande">
                <span className="cifrao">R$</span>2,89
                <span className="valor-unidade">Unid.</span>
              </h4>
            </div>
          </div>

          <div className="promo-item">
            <img src={lar_doce_lar_500ml} alt="Urca Lava Roupas Liquído 1L" />
            <p>Limpeza Perfumada Lar doce Lar 500ml</p>
            <div className="title-oferta-medio">
              <p className="embalagem-info"></p>
              <h4 className="valor-item-grande">
                <span className="cifrao">R$</span>2,89
                <span className="valor-unidade">Unid.</span>
              </h4>
            </div>
          </div>

          <div className="promo-item">
            <img
              src={saco_de_lixo_verom}
              alt="Saco de Lixo Verom - 50L ou 100L"
            />
            <p>Saco de Lixo Verom - 50L ou 100L</p>
            <div className="title-oferta-medio">
              <p className="embalagem-info"></p>
              <h4 className="valor-item-grande">
                <span className="cifrao">R$</span>12,99
                <span className="valor-unidade">Unid.</span>
              </h4>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Mercearia;
