import "./padaria.css";
import "../global.css";
import emabaladosBolosPalitos from "./padaria/emabaladosBolosPalitos.png";
import paoDeQueijo from "./padaria/paoDeQueijo.png";
import paoFrances from "./padaria/paoFrances.png";

function Padaria() {
  return (
    <div>
      <div className="padaria titulo-setor" id="padaria">
        PADARIA
      </div>

      <div
        className="padaria promocoes-container img-encartelados"
        style={{
          background: "linear-gradient(to right, black, #5c5b5b, black)",
        }}
      >
        <div className="promo-item">
          <img src={paoFrances} alt="Promo 1" />
          <p> Pão Francês/Filão Saltarelli</p>
          <div className="title-oferta-medio">
            <p className="embalagem-info"></p>
            <h4 className="valor-item-grande">
              <span className="cifrao">R$</span>16,99
              <span className="valor-unidade">Kg.</span>
            </h4>
          </div>
        </div>

        <div className="promo-item">
          <img src={paoDeQueijo} alt="Promo 1" />
          <p> Pão de Queijo Saltarelli </p>

          <div className="title-oferta-medio">
            <p className="embalagem-info"></p>
            <h4 className="valor-item-grande">
              <span className="cifrao">R$</span>34,99
              <span className="valor-unidade">Kg.</span>
            </h4>
          </div>
        </div>

        {/* <div className="img-encartelados">
          <img
            src={emabaladosBolosPalitos}
            alt="Palitos de Chocolate, Bolos e mais"
          />
          <p> PALITOS CHOCOLATE, BOLOS e MUITO MAIS. </p>
        </div> */}
      </div>
    </div>
  );
}

export default Padaria;
