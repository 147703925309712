import "./acougue.css";
import pernilSemOsso from "./acougue/pernil-sem-osso.png";
import linguicaCaipira from "./acougue/linguica_caipira.png";
import figadoBovino from "./acougue/figado_Bovino.png";
import fileFrango from "../../assets/filePeitoFrango.png";
import frangoCaipira from "../../assets/frangoInteiro.png";
import tulipa_Temperada_Fundo_Preto from "./acougue/tulipa_Temperada_FundoPreto.png";
import { useState } from "react";
import { FaChevronDown } from "react-icons/fa";

function Acougue() {
  const [isRecipeOpen, setRecipeOpen] = useState(false);

  const toggleRecipe = () => {
    setRecipeOpen(!isRecipeOpen);
  };

  const closeRecipe = () => {
    setRecipeOpen(false);
  };

  const copyRecipe = () => {
    const recipeText = `
      Costela Assada no Forno
      
      Ingredientes:
      - 1 kg de costela
      - 1 cebola
      - 1 limão
      - 1 pitada de pimenta do reino, alecrim, tomilho, orégano e manjericão
      - 1 folha de louro
      - 1 sal grosso
      - 2 dentes de alho
      - 3 colheres de azeite

      Preparação:
      1. Em uma forma coloque papel alumínio por baixo, em seguida coloque a costela temperada com sal grosso a gosto.
      2. Em um recipiente misture todos os temperos no suco de limão.
      3. Mexa bem, reserve.
      4. Na costela pique os alhos e a cebola em rodelas, coloque por cima da carne, despeje o azeite por cima também.
      5. Agora despeje o suco de limão com os temperos na carne, embrulhe toda a carne com o papel e espere 30 minutos.
      6. Leve ao forno pré-aquecido, deixe por 1 hora.
      7. Depois tire o papel e deixe por mais 30 minutos.
      8. Servir com um delicioso arroz e uma salada de rúcula.
    `;
    navigator.clipboard.writeText(recipeText);
    alert("Receita copiada para a área de transferência!");
  };

  return (
    <div id="acougue">
      <div className="acougue titulo-setor" id="acougue">
        AÇOUGUE
      </div>

      <div className="container-ofertas promocoes-container">
        <div className="promo-item">
          <img src={fileFrango} alt="Filé de Frango" />
          <p> Filé de Frango </p>
          <br />
          <div className="title-oferta-medio">
            <h4 className="valor-item-grande">
              <span className="cifrao">R$</span>19,99
              <span className="valor-unidade">Kg.</span>
            </h4>
          </div>
        </div>

        <div className="promo-item">
          <img src={frangoCaipira} alt="Frango Inteiro Caipira Nhô Bento" />
          <p>Frango Inteiro Caipira Nhô Bento</p>
          <div className="title-oferta-medio">
            <h4 className="valor-item-grande">
              <span className="cifrao">R$</span>19,99
              <span className="valor-unidade">Kg.</span>
            </h4>
          </div>
        </div>

        <div className="promo-item">
          <img src={tulipa_Temperada_Fundo_Preto} alt="tulipa Temperada" />
          <p> Tulipa Temperada</p>
          <div className="title-oferta-medio">
            <h4 className="valor-item-grande">
              <span className="cifrao">R$</span>22,99
              <span className="valor-unidade">Kg.</span>
            </h4>
          </div>
        </div>

        <div className="promo-item">
          <img src={linguicaCaipira} alt="Linguiça Caipira" />
          <p> Linguiça Caipira </p>
          <div className="title-oferta-medio">
            <h4 className="valor-item-grande">
              <span className="cifrao">R$</span>21,99
              <span className="valor-unidade">Kg.</span>
            </h4>
          </div>
        </div>

        <div className="promo-item">
          <img src={pernilSemOsso} alt="Pernil sem Osso" />
          <p> Pernil sem Osso </p>
          <div className="title-oferta-medio">
            <h4 className="valor-item-grande">
              <span className="cifrao">R$</span>21,99
              <span className="valor-unidade">Kg.</span>
            </h4>
          </div>
        </div>

        <div className="promo-item">
          <img src={figadoBovino} alt="Fígado Bovino" />
          <p> Fígado Bovino </p>
          <div className="title-oferta-medio">
            <h4 className="valor-item-grande">
              <span className="cifrao">R$</span>16,99
              <span className="valor-unidade">Kg.</span>
            </h4>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Acougue;
