import React, { useRef, useState, useEffect } from "react";
import video_acougue from "../../assets/video_acougue.mp4";
import "./video.css";

function VideoPlayer() {
  const videoRef = useRef(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);

  // Alterna entre play e pause
  const togglePlay = () => {
    if (!isLoaded) return; // Certifique-se de que o vídeo está carregado
    if (videoRef.current.paused) {
      videoRef.current.play();
      setIsPlaying(true);
    } else {
      videoRef.current.pause();
      setIsPlaying(false);
    }
  };

  // Manipula o final do vídeo
  const handleVideoEnd = () => {
    setIsPlaying(false);
    videoRef.current.currentTime = 0; // Reseta o vídeo para o início
  };

  // Detecta quando o vídeo foi carregado
  const handleLoadedData = () => {
    setIsLoaded(true); // Marca o vídeo como carregado
  };

  return (
    <div className="video-container">
      {/* Vídeo */}
      <video
        ref={videoRef}
        src={video_acougue}
        className="video-player"
        playsInline
        onEnded={handleVideoEnd}
        onLoadedData={handleLoadedData} // Detecta quando o vídeo foi carregado
      />

      {/* Botão Play/Pause */}
      {isLoaded && (
        <button onClick={togglePlay} className="video-play-button">
          {isPlaying ? "❚❚" : "►"}
        </button>
      )}
    </div>
  );
}

export default VideoPlayer;
