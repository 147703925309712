import React, { useEffect, useRef, useState } from "react";
import "./promocoes.css";
import "../global.css";
import logoPromocao from "./logo-promocao/black-friday-saltarelli.png";
import energeticoMonsterGreen from "./images-promocoes/energeticoMonsterGreen.png";
import amstelFardoLata350ml from "./images-promocoes/amstelFardoLata350ml.png";
import cristalLataFardo from "./images-promocoes/cervejaCristalLataFardo.png";
import paoFrances from "./images-promocoes/pao.png";
import paoDeAlho from "../../assets/pao_de_alho_mencucini.png";
import lasanha_seara from "../../assets/lasanha_seara.png";
import paodequeijo from "./images-promocoes/paodequeijo.png";
import cocaColaZero from "./images-promocoes/cocaColaZero.png";
import sucoDelValleSabores from "./images-promocoes/sucoDelValleSabores.png";
import cuidadosComCabelo from "./images-promocoes/cuidadosComCabelo.png";
import churrasco_ContraFile_Frango from "./images-promocoes/churrasco_CarneBovina_Frango.png";
import tulipa_Temperada_Fundo_Preto from "./images-promocoes/tulipa_Temperada_FundoPreto.png";
import coxinha_da_asa from "../../assets/coxinha-da-asa.png";
import cerveja_sabores from "./images-promocoes/cervejas_sabores.png";
import pontaAlcatraAngus from "./images-promocoes/pontaAlcatraAngus.png";
import carneMoida from "../../assets/carne-moida.png";
import paleta_bovina from "./images-promocoes/paleta_bovina.png";
import cervejaLataImperio from "./images-promocoes/cervejaLataImperio.png";
import whatssappIcon from "./images-promocoes/whatssapp.svg";
import cervejaPuroMalte from "./images-promocoes/cervejaPuroMalte.png";
import kitChurrasco from "./images-promocoes/kit-churrasco.png";
import videoFilao from "../../assets/videoFilao.mp4";
import vinhoParatudo from "../../assets/vinhoParatudo.png";
import vinhoBordo from "../../assets/vinhoTintoBordo.png";
import pingaPirassununga from "../../assets/pingaPirassununga.png";
import pingaMoenda from "../../assets/pingaMoenda.png";
import guaranaMaca from "../../assets/guaranaMaca.png";
import aguaTonica from "../../assets/aguaTonica.png";
import paoFrancesCapa from "../../assets/PaoFrancesCapa.png"; // importando a imagem da capa
import capavideoBalcaoAcougue from "../../assets/capa_balcao_acougue_mascote.png";
import costelaBafo from "../../assets/costela.png";
import pontaDePeito from "../../assets/ponta_de_Peito.png";
import budweiserFardolata350ml from "../../assets/budweiserFardolata350ml.png";
import cerveja_Spaten_lata from "../../assets/cerveja_Spaten_lata.png";
import carvao_pitoko_3KG from "../../assets/carvao-pitoko-3kg.png";
import carvao_pitoko_8KG from "../../assets/carvao-pitoko-8kg.png";
import pernilSemOsso from "../../assets/pernil-sem-osso.png";
import panceta from "../../assets/panceta.png";
import linguica_churrasco from "../../assets/linguica_churrasco.png";
import linguicaCaipira from "../../assets/linguica_caipira.png";
import linguicaToscana from "../../assets/linguica_Toscana.png";
import fileFrango from "../../assets/filePeitoFrango.png";
import contra_file_grill from "../../assets/contra-file-grill.png";
import video_acougue from "../../assets/video_acougue.mp4";
import VideoPlayer from "../Video_Player";

import { FaPlay, FaPause } from "react-icons/fa";

function Promocoes() {
  return (
    <div className="container-ofertas" id="promocoes">
      <div className="tag-promocoes-container">
        <a
          href="https://chat.whatsapp.com/GdYrxmhf7xq3VcdIxXN1Vl"
          target="_blank"
          rel="noopener noreferrer"
          className="whatsapp-fixo"
          id="grupoOfertas"
        >
          <img
            src={whatssappIcon}
            alt="WhatsApp"
            id="whatsappIcon"
            style={{ width: "55px", height: "auto" }}
          />
          <div className="tooltip" id="whatsappTooltip">
            FAÇA PARTE DO GRUPO DO SALTARELLI
          </div>
        </a>

        <div className="container-titulo-interativo">
          <div className="titulo-interativo linha-um">NATAL E ANO NOVO</div>
          <div className="titulo-interativo linha-dois">É NO SALTARELLI</div>
          <br />
          <br />
          <br />
          <br />
          <VideoPlayer />
        </div>

        <div className="promocoes-container">
          <div className="promo-item">
            <img src={costelaBafo} alt="Costela Bafo" />
            <p>Costela Bafo </p>
            <div className="title-oferta-medio">
              <h4 className="valor-item-grande">
                <span className="cifrao">R$</span>25,99
                <span className="valor-unidade">Kg.</span>
              </h4>
            </div>
          </div>

          <div className="promo-item">
            <img src={pontaDePeito} alt="Ponta de Peito" />
            <p>Ponta de Peito</p>
            <div className="title-oferta-medio">
              <h4 className="valor-item-grande">
                <span className="cifrao">R$</span>34,99
                <span className="valor-unidade">Kg.</span>
              </h4>
            </div>
          </div>

          <div className="promo-item">
            <img src={pontaAlcatraAngus} alt="Ponta de Alcatra" />
            <p>Ponta de Alcatra</p>
            <p className="embalagem-info"></p>
            <div className="title-oferta-medio">
              <h4 className="valor-item-grande">
                <span className="cifrao">R$</span>42,99
                <span className="valor-unidade">Kg.</span>
              </h4>
            </div>
          </div>

          <div className="promo-item">
            <img src={contra_file_grill} alt="Paleta" />
            <p>Contra-Filé Grill</p>
            <p className="embalagem-info"></p>
            <div className="title-oferta-medio">
              <h4 className="valor-item-grande">
                <span className="cifrao">R$</span>55,99
                <span className="valor-unidade">Kg.</span>
              </h4>
            </div>
          </div>

          <div className="promo-item">
            <img src={paleta_bovina} alt="Paleta" />
            <p>Paleta</p>
            <div className="title-oferta-medio">
              <h4 className="valor-item-grande">
                <span className="cifrao">R$</span>34,99
                <span className="valor-unidade">Kg.</span>
              </h4>
            </div>
          </div>

          <div className="promo-item">
            <img src={carneMoida} alt="Carne Moída" />
            <p>Carne Moída</p>
            <div className="title-oferta-medio">
              <h4 className="valor-item-grande">
                <span className="cifrao">R$</span>34,99
                <span className="valor-unidade">Kg.</span>
              </h4>
            </div>
          </div>

          <div className="promo-item">
            <img src={fileFrango} alt="Filé de Frango" />
            <p> Filé de Frango </p>
            <br />
            <div className="title-oferta-medio">
              <h4 className="valor-item-grande">
                <span className="cifrao">R$</span>19,99
                <span className="valor-unidade">Kg.</span>
              </h4>
            </div>
          </div>

          <div className="promo-item">
            <img src={tulipa_Temperada_Fundo_Preto} alt="tulipa Temperada" />
            <p> Tulipa Temperada</p>
            <div className="title-oferta-medio">
              <h4 className="valor-item-grande">
                <span className="cifrao">R$</span>22,99
                <span className="valor-unidade">Kg.</span>
              </h4>
            </div>
          </div>

          <div className="promo-item">
            <img src={coxinha_da_asa} alt="Coxinha da Asa de Frango" />
            <p> Coxinha da Asa de Frango</p>
            <div className="title-oferta-medio">
              <h4 className="valor-item-grande">
                <span className="cifrao">R$</span>17,99
                <span className="valor-unidade">Kg.</span>
              </h4>
            </div>
          </div>

          <div className="promo-item">
            <img src={linguicaToscana} alt="Linguiça Toscana" />
            <p> Linguiça Toscana </p>
            <div className="title-oferta-medio">
              <h4 className="valor-item-grande">
                <span className="cifrao">R$</span>18,99
                <span className="valor-unidade">Kg.</span>
              </h4>
            </div>
          </div>

          <div className="promo-item">
            <img src={linguica_churrasco} alt="Linguiça Churrasco" />
            <p> Linguiça Churrasco </p>
            <div className="title-oferta-medio">
              <h4 className="valor-item-grande">
                <span className="cifrao">R$</span>18,99
                <span className="valor-unidade">Kg.</span>
              </h4>
            </div>
          </div>

          <div className="promo-item">
            <img src={linguicaCaipira} alt="Linguiça Caipira" />
            <p> Linguiça Caipira </p>
            <div className="title-oferta-medio">
              <h4 className="valor-item-grande">
                <span className="cifrao">R$</span>23,99
                <span className="valor-unidade">Kg.</span>
              </h4>
            </div>
          </div>

          <div className="promo-item">
            <img src={pernilSemOsso} alt="Pernil sem Osso" />
            <p> Pernil sem Osso </p>
            <div className="title-oferta-medio">
              <h4 className="valor-item-grande">
                <span className="cifrao">R$</span>24,99
                <span className="valor-unidade">Kg.</span>
              </h4>
            </div>
          </div>

          <div className="promo-item">
            <img src={panceta} alt="Panceta" />
            <p> Panceta </p>
            <div className="title-oferta-medio">
              <h4 className="valor-item-grande">
                <span className="cifrao">R$</span>32,99
                <span className="valor-unidade">Kg.</span>
              </h4>
            </div>
          </div>

          <div className="promo-item">
            <img src={paoDeAlho} alt="Pão de Alho Mencucini" />
            <p> Pão de Alho Mencucini</p>
            <div className="title-oferta-medio">
              <h4 className="valor-item-grande">
                <span className="cifrao">R$</span>9,99
                <span className="valor-unidade">Uni.</span>
              </h4>
            </div>
          </div>

          <div className="promo-item">
            <img src={lasanha_seara} alt="Lasanha Bolonhesa Seara" />
            <p>Lasanha Bolonhesa Seara</p>
            <div className="title-oferta-medio">
              <h4 className="valor-item-grande">
                <span className="cifrao">R$</span>14,99
                <span className="valor-unidade">Kg.</span>
              </h4>
            </div>
          </div>

          <div className="promo-item">
            <img src={cervejaLataImperio} alt="cerveja Lata Imperio" />
            <p> Império lata 350ml </p>
            <p className="embalagem-info">Fardo c/15 R$ 41,85</p>
            <div className="title-oferta-medio">
              <h4 className="valor-item-grande">
                <span className="cifrao">R$</span>2,79
                <span className="valor-unidade">unid.</span>
              </h4>
            </div>
          </div>

          <div className="promo-item">
            <img src={cervejaPuroMalte} alt="cerveja Puro Malte" />
            <p> Puro Malte lata 350ml </p>
            <p className="embalagem-info">Fardo c/12 R$ 27,50</p>
            <div className="title-oferta-medio">
              <h4 className="valor-item-grande">
                <span className="cifrao">R$</span>2,29
                <span className="valor-unidade">unid.</span>
              </h4>
            </div>
          </div>

          <div className="promo-item">
            <img src={amstelFardoLata350ml} alt="Amstel Fardo Lata 350ml" />
            <p> Amstel lata 350ml</p>
            <p className="embalagem-info">Fardo c/12 R$ 47,88</p>
            <div className="title-oferta-medio">
              <h4 className="valor-item-grande">
                <span className="cifrao">R$</span>3,99{" "}
                <span className="valor-unidade">unid.</span>
              </h4>
            </div>
          </div>

          <div className="promo-item">
            <img src={cristalLataFardo} alt="cristal Lata Fardo" />
            <p> Crystal lata 350ml</p>
            <p className="embalagem-info">Fardo c/12 R$ 27,50</p>
            <div className="title-oferta-medio">
              <h4 className="valor-item-grande">
                <span className="cifrao">R$</span>2,29
                <span className="valor-unidade">unid.</span>
              </h4>
            </div>
          </div>

          <div className="promo-item">
            <img
              src={budweiserFardolata350ml}
              alt="budweiser Fardo lata 350ml"
            />
            <p> Budweiser lata 350ml </p>
            <p className="embalagem-info">Fardo c/12 R$ 45,48</p>
            <div className="title-oferta-medio">
              <h4 className="valor-item-grande">
                <span className="cifrao">R$</span>3,79
                <span className="valor-unidade">unid.</span>
              </h4>
            </div>
          </div>

          <div className="promo-item">
            <img src={cerveja_Spaten_lata} alt="Spaten Fardo Lata 350ml" />
            <p> Spaten lata 350ml </p>
            <p className="embalagem-info">Fardo c/12 R$ 55,10</p>
            <div className="title-oferta-medio">
              <h4 className="valor-item-grande">
                <span className="cifrao">R$</span>4,59
                <span className="valor-unidade">unid.</span>
              </h4>
            </div>
          </div>

          <div className="promo-item">
            <img src={carvao_pitoko_3KG} alt="Carvão Pitoko 3KG" />
            <p> Carvão Pitoko 3KG </p>
            <div className="title-oferta-medio">
              <h4 className="valor-item-grande">
                <span className="cifrao">R$</span>18,99
                <span className="valor-unidade">unid.</span>
              </h4>
            </div>
          </div>

          <div className="promo-item">
            <img src={carvao_pitoko_8KG} alt="Carvão Pitoko 8KG" />
            <p> Carvão Pitoko 8KG </p>
            <div className="title-oferta-medio">
              <h4 className="valor-item-grande">
                <span className="cifrao">R$</span>47,99
                <span className="valor-unidade">unid.</span>
              </h4>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Promocoes;
